<template>
  <div class="card">
    <!--<div class="card-header">-->
    <!--<h5> Form Designer</h5>-->
    <!--</div>-->
    <div class="card-body">

      <ul class="nav nav-tabs">
        <li class="nav-item"><a href="#basic-tab1" class="nav-link active" data-toggle="tab">Form Designer</a></li>
        <li class="nav-item"><a href="#basic-tab2" class="nav-link" data-toggle="tab">Form Viewer</a></li>

      </ul>

      <div class="tab-content">
        <div class="tab-pane fade show active" id="basic-tab1">
          <div id='builder' >
          </div>


          <div class="form-group row">
            <label class="col-lg-2 col-form-label">Form Name:</label>
            <div class="col-lg-10">
              <input type="text" class="form-control" placeholder="Form Name" maxlength="100" autocomplete="off" v-if="formdata" v-model="formdata.name" >
            </div>
          </div>

          <!--<div class="form-group row">-->
            <!--<label class="col-lg-2 col-form-label">Path:</label>-->
            <!--<div class="col-lg-10">-->
              <!--<input type="text" class="form-control" placeholder="Path Name" autocomplete="off" v-if="formdata" v-model="formdata.path" >-->
            <!--</div>-->
          <!--</div>-->

        </div>

        <div class="tab-pane fade" id="basic-tab2">
          <div id='viewer' >
          </div>
        </div>

      </div>

      <!--<div id='builder' >-->
      <!--</div>-->

    </div>
    <div class="card-footer text-right">
      <button class="btn btn-outline-info" @click="clear">Clear</button>
      <button type="button" class="btn btn-outline-secondary" @click="saveDocument">Save</button>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'

  export default {
    name: 'DesignerView',
    data(){
      return {
        formbuilder: {},
        formdata: JSON.parse('{"id":"","code":0,"name":"","path":"","type":"","form_data":""}'),
      }
    },
    created(){

    },
    mounted(){
      let self = this;
      let url = `${process.env.VUE_APP_ROOT_API}v1/forms/`;
      Formio.icons = 'fontawesome';
      Formio.builder(document.getElementById('builder')).then(function (form) {
        self.$data.formbuilder = form;
        const requestOptions = {
          method: 'POST',
          mode:"cors",
          headers: userService.authHeader()
        };

        form.nosubmit = true;
        // Triggered when they click the submit button.
        form.on('submit', function(submission) {

          console.log( JSON.stringify(form.schema) );
          alert(JSON.stringify(form.schema));

          return fetch(url, requestOptions).then(
            response => {
              form.emit('submitDone', submission)
              response.json()
            });
        });
      });
      $('.nav-tabs a').on('show.bs.tab', function(){
        self.formViewer();
      });
    },
    methods:{
      clear(){
        let form = Formio.builder(document.getElementById('builder'));
        form.schema = {};
      },
      formViewer(){
        let self = this;
        Formio.createForm(document.getElementById('viewer'), self.$data.formbuilder.schema
        ).then(function(form) {
          form.on('submit', (submission) => {
            console.log('The form was just submitted!!!');
          });
          form.on('error', (errors) => {
            console.log('We have errors!');
          })
        });
      },
      loadDocument(id){
        let self = this;
        self.$data.formdata.form_data = JSON.stringify(self.$data.formbuilder.schema);
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/form/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (data.accepted) {
            self.$data.formdata = resp.data;
          } else {
            swal({ title: 'Oh noes!', text: data.message, icon: 'error' });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), icon: 'error' });
        });

        Formio.createForm(document.getElementById('viewer'), {
          components: [
            {
              type: 'textfield',
              key: 'firstName',
              label: 'First Name',
              placeholder: 'Enter your first name.',
              input: true
            },
            {
              type: 'textfield',
              key: 'lastName',
              label: 'Last Name',
              placeholder: 'Enter your last name',
              input: true
            },
            {
              type: 'button',
              action: 'submit',
              label: 'Submit',
              theme: 'primary'
            }
          ]
        });

      },
      saveDocument(){
        let self = this;
        try{

          self.$data.formdata.form_data = JSON.stringify(self.$data.formbuilder.schema);
          const requestOptions = {
            method: (self.$data.formdata.code == 0 ? 'POST' : 'PUT'),
            mode: 'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(self.$data.formdata)
          }

          fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/form/`, requestOptions).then(userService.handleResponse).then(function (data) {
            if (data.accepted) {
              swal({ title: 'Success!', text: data.message, icon: 'success' });
            } else {
              swal({ title: 'Oh noes!', text: data.message, icon: 'error' });
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), icon: 'error' });
          });

        }catch (e) {
          alert(e.toString());
        }
      }
    }
  }
</script>

<style scoped>

</style>
